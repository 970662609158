require('./src/styles/global.scss');

// Suppress defaultProps warning
const error = console.error;
console.error = function(...args) {
  if (/defaultProps/.test(args[0])) return;
  error(...args);
};

function initGTM() {
    if (window.isGTMLoaded) {
        return;
    }

    // Initialize dataLayer if not already initialized
    window.dataLayer = window.dataLayer || [];
    window.isGTMLoaded = true;

    const script = document.createElement('script');
    script.async = true;
    script.src = `${process.env.GATSBY_GTM_ORIGIN_URL}/gtm.js?id=${process.env.GATSBY_GTM_TRACKING_ID}`;

    // GTM script onload handler
    script.onload = () => {
        function gtag() {
            window.dataLayer.push(arguments); // eslint-disable-line
        }

        gtag('js', new Date());
        gtag('config', `${process.env.GATSBY_GTM_TRACKING_ID}`);
    };

    // Error handling for GTM script load failure
    script.onerror = () => {
        console.error('GTM failed to load');
    };

    document.head.appendChild(script);
}

function loadGTM(event) {
    initGTM();

    // Remove all GTM-related event listeners once GTM is loaded
    document.removeEventListener('scroll', loadGTM);
    document.removeEventListener('click', loadGTM);
    document.removeEventListener('touchstart', loadGTM);
    document.removeEventListener('visibilitychange', loadGTMOnVisibilityChange);
}

function loadGTMOnVisibilityChange() {
    if (document.visibilityState === 'visible') {
        initGTM();
        // Remove the visibility change listener once GTM is loaded
        document.removeEventListener('visibilitychange', loadGTMOnVisibilityChange);
    }
}

exports.onClientEntry = () => {
    // Fallback to loading GTM after 5 seconds if no user interaction occurs
    const fallbackTimeout = setTimeout(initGTM, 5000);

    // Load GTM on user interaction (scroll, click, touchstart)
    document.addEventListener('scroll', (event) => {
        loadGTM(event);
        clearTimeout(fallbackTimeout);  // Clear fallback timeout once interaction happens
    }, { passive: true });

    document.addEventListener('click', (event) => {
        loadGTM(event);
        clearTimeout(fallbackTimeout);  // Clear fallback timeout once interaction happens
    }, { passive: true });

    document.addEventListener('touchstart', (event) => {
        loadGTM(event);
        clearTimeout(fallbackTimeout);  // Clear fallback timeout once interaction happens
    }, { passive: true });

    // Load GTM when the page becomes visible (using the Page Visibility API)
    document.addEventListener('visibilitychange', loadGTMOnVisibilityChange);
};

exports.onRouteUpdate = ({ location }) => {
    // Initialize or use existing dataLayer
    window.dataLayer = window.dataLayer || [];

    // Push route-change or page-view event if GTM is already loaded
    if (window.isGTMLoaded) {
        setTimeout(() => {
            window.dataLayer.push({
                event: 'page_view',
                page_path: location.pathname,
                page_title: document.title,
                page_location: window.location.href,
            });
        }, 50);
    }
};